<template>
    <div class="sales">
        <vx-card class="mb-base no-shadow">
            <div class="vx-row mt-6 mb-6">
                <div class="vx-col">
                    <label class="vs-input--label">{{ $t("shops.from_date") }}</label>
                    <datepicker
                        v-bind:class="{
                            'danger-border':
                                (errorFromDate && errors.has('from_date'))
                        }"
                        :inline="false"
                        :mondayFirst="true"
                        v-validate="'required'"
                        :danger="errorFromDate"
                        :danger-text="$t('shops.errors.from_date_is_required')"
                        v-model="form.fromDate"
                        name="from_date">
                    </datepicker>
                    <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
                        <span v-if="errorFromDate && errors.has('from_date')"> {{ $t("shops.errors.from_date_is_required") }} </span>
                    </div>
                </div>
                <div class="vx-col">
                    <label class="vs-input--label">{{ $t("shops.to_date") }}</label>
                    <datepicker
                        v-bind:class="{
                            'danger-border':
                                (errorToDate && errors.has('to_date')) || !checkDate(form.fromDate, form.toDate)
                        }"
                        :inline="false"
                        :mondayFirst="true"
                        v-validate="'required'"
                        :danger="errorToDate"
                        :danger-text="$t('shops.errors.to_date_is_required')"
                        v-model="form.toDate"
                        name="to_date"
                        ref="to_date">
                    </datepicker>
                    <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
                        <span v-if="errorToDate && errors.has('to_date')"> {{ $t("shops.errors.to_date_is_required") }}</span>
                        <span v-if="!checkDate(form.fromDate, form.toDate)">{{ $t("shops.errors.to_date_greater") }}</span>
                    </div>
                </div>
            </div>
            <vs-button @click="validateAndSubmit(form)" class="mt-base" type="filled">
                {{ $t("shops.search") }}
            </vs-button>
            <div v-if="salesList['amount'] !== undefined">
                <vs-divider />
                <h2 class="pt-4 pb-2">{{ $t("shops.order_details.title") }}</h2>
                <data-table
                    :data="productsData"
                    :options="tableOptions"
                />
                <h2 class="pt-12 pb-2">{{ $t("shops.keycards") }}</h2>
                <data-table
                    :data="keycardsData"
                    :options="tableOptions"
                />
                <h2 class="pt-12 pb-2">{{ $t("shops.extras") }}</h2>
                <data-table
                    :data="extrasData"
                    :options="tableOptions"
                />
                <h2 class="pt-12 pb-2">{{ $t("shops.total") }}</h2>
                <data-table
                    :data="totalData"
                    :options="tableOptions"
                />
            </div>
        </vx-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import Datepicker from "vuejs-datepicker";
import i18n from "@/i18n";
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import { DateTime } from "luxon";

export default {
    name: "Sales",
    mixins: [notifications],
    components: {
        Datepicker,
        DataTable
    },
    data() {
        return {
            errorFromDate: false,
            errorToDate: false,
            form: {
                fromDate: null,
                toDate: null
            },
        };
    },
    computed: {
        ...mapGetters("shops/sales", {
            salesList: "getAll",
        }),
        tableOptions() {
            return {
                actions: {
                    create: false,
                    update: false,
                    delete: false,
                    cancelButton: false
                },
                filters: false,
                pagination: false
            }
        },
        productsData() {
            let products = []
            const details = this.salesList.details;
            if(this.salesList.details.products)
                Object.entries(this.salesList.details.products).forEach(product => products.push(product[1]))
            products.push({name: details.name, amount: details.amount, quantity: details.quantity, days: details.days})
            return products
        },

        keycardsData() {
            let keycards = []
            const details = this.salesList.keycards;
            if(this.salesList.keycards.products)
                Object.entries(this.salesList.keycards.products).forEach(keycard => keycards.push(keycard[1]))
            keycards.push({name: details.name, amount: details.amount, quantity: details.quantity})
            return keycards
        },

        extrasData() {
            let extras = []
            const details = this.salesList.extras;
            if(this.salesList.extras.products)
                Object.entries(this.salesList.extras.products).forEach(extra => extras.push(extra[1]))
            extras.push({name: details.name, amount: details.amount, quantity: details.quantity})
            return extras
        },

        totalData() {
            return [{name: this.salesList.name, amount: this.salesList.amount, days: this.salesList.details.days}]
        }
    },
    methods: {
        ...mapActions("shops/sales", {
            loadSales: "load",
        }),
        validateAndSubmit(form) {
            this.$validator.validateAll().then(result => {
                if (result && this.checkDate(form.fromDate, form.toDate)) {
                    this.$vs.loading()
                    this.loadSales({created_at_from: form.fromDate, created_at_to: form.toDate}).then(() => this.$vs.loading.close())
                } else {
                    this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.sales_validation_failed"));
                    if (!form.fromDate) this.errorFromDate = true;
                    if (!form.toDate) this.errorToDate = true;
                }
            });
        },
        checkDate(from, to) {
            if(from && to) {
                let fromDate = DateTime.fromJSDate(from)
                let toDate = DateTime.fromJSDate(to)

                return fromDate <= toDate
            }
            else
                return true
        }
    },
    created() {
        this.$store.commit("shops/sales/RESET_RESOURCE");
    }

};
</script>

<style>
.sales table tr.tr-values:last-child {
    @apply font-bold;
}

.sales table .sort-th {
    display: none;
}

.sales table th .vs-table-text {
    cursor: default;
}
</style>